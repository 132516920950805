import React, { Component } from 'react';
import { Text, Loader, Image, Button } from 'components/atoms';
import Color from 'color';
import get from 'lodash/get';
import styled from '@emotion/styled';
import TextField from '@material-ui/core/TextField';
import CloudApi from 'api/cloudApi';
import { Link } from 'gatsby';

// REDUX
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { getNewNotification, updateSurvey } from 'modules/notification/actions';
import {
  selectData,
  selectSurveysStatus
} from 'modules/notification/selectors';

//COMPONENTS
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SurveysReward from 'components/molecules/SurveysReward';

//ICONS
import Back from '@material-ui/icons/KeyboardArrowLeft';

// HOC
import withProfile from 'hocs/withProfile';

//IMAGES
import SadPumpkin from 'assets/pumpkin-cry.png';
// import { red, pink } from '@material-ui/core/colors';

const StyledRadio = styled(Radio)(({ color }) => ({
  '&.checked': {
    color
  }
}));

const StyledTextField = styled(TextField)(({ color }) => ({
  '.underline:before': {
    borderColor: '#a5a5a5'
  },
  '.focused:after': {
    borderColor: `${color} !important`
  }
}));

const styles = theme => {
  return {
    layout: {
      width: 'auto',
      display: 'block', // Fix IE11 issue.
      [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    },
    paper: {
      marginTop: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    surveysContainer: {
      position: 'relative',
      minHeight: '100vh'
    },
    surveysForm: {
      backgroundColor: 'white',
      padding: 20,
      position: 'relative',
      top: -82,
      width: '60%'
    },
    header: {
      height: 200,
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 20,
      position: 'relative'
    },
    logo: {
      width: 50,
      height: 50,
      borderRadius: '50%',
      border: '2px solid #ffffff'
    },
    flexBox: {
      display: 'flex',
      alignItems: 'center'
    },
    profileImage: {
      width: 35,
      height: 35,
      borderRadius: '50%',
      border: '2px solid #ffffff'
    },
    formBox: {
      marginTop: 20
    },
    backIcon: {
      position: 'absolute',
      color: 'white',
      top: 13,
      left: 6
    },
    rewardImage: {
      width: 80,
      height: 80,
      borderRadius: 10,
      marginRight: 20
    },
    rewardPreview: {
      display: 'flex',
      marginBottom: 25
    },
    otherInputBox: {
      display: 'flex',
      alignItems: 'center'
    },
    otherInput: {
      marginLeft: 10,
      padding: 0,
      width: '70%'
    }
  };
};

class Survey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answers: [],
      survey: null,
      reward: null,
      finishSurvey: false,
      getReward: false,
      canDoSurvey: false,
      loading: false
    };
  }

  setSurveyState = survey => {
    const markAsSubmitted = get(survey, 'markAsSubmitted', false);
    const reward = get(survey, 'reward', false);
    if (markAsSubmitted) {
      this.setState({
        survey,
        finishSurvey: true,
        reward,
        getReward: true
      });
    } else {
      this.setState({
        survey,
        canDoSurvey: true,
        reward
      });
    }
  };

  handleChange = (index, event) => {
    var answers = this.state.answers;
    answers[index] = event.target.value;
    this.setState({ answers });
  };

  renderOptionLabel = (option, index, choiceIndex) => {
    const { classes } = this.props;
    const { survey } = this.state;
    if (get(option, 'other', false)) {
      return (
        <div className={classes.otherInputBox}>
          <p>other :</p>
          <StyledTextField
            id="name"
            name="name"
            value={survey.items[index].options[choiceIndex].value}
            onChange={e =>
              this.handleChangeOther(e, index, choiceIndex, option.id)
            }
            disableUnderline
            InputProps={{
              classes: {
                underline: 'underline',
                focused: 'focused'
              }
            }}
            className={classes.otherInput}
            color={survey.brandColor}
          />
        </div>
      );
    } else {
      return option.value;
    }
  };

  checkAnswerNotEmpty = () => {
    const { answers, survey } = this.state;
    let empty = false;
    answers.forEach((answer, index) => {
      const checkAnswer = survey.items[index].options.find(
        option => option.id === answer
      );
      if (checkAnswer.value === '') empty = true;
    });
    return empty;
  };

  checkDisableButton = () => {
    const { survey, answers } = this.state;
    return (
      answers.includes(undefined) ||
      (answers.length < survey.items.length || this.checkAnswerNotEmpty())
    );
  };

  handleChangeOther = (event, index, choiceIndex, optionId) => {
    const { survey } = this.state;
    let surveyChangeData = survey;
    surveyChangeData.items[index].options[choiceIndex].value =
      event.target.value;
    var answers = this.state.answers;
    answers[index] = optionId;
    this.setState({ survey: surveyChangeData, answers });
  };

  submitAnswers = () => {
    this.setState({ loading: true });
    const { survey } = this.state;
    const { profile, updateSurvey } = this.props;
    let answers = this.state.answers.map((answer, index) => {
      return {
        title: survey.items[index].title,
        questionId: survey.items[index].id,
        answer: survey.items[index].options.find(option => option.id === answer)
      };
    });
    CloudApi.submitAnswer(survey.brandId, survey.id, {
      answers,
      uid: profile.uid,
      reward: survey.reward
    })
      .then(response => {
        const rewardFromNotification = get(response, 'reward', false);
        updateSurvey({
          markAsSubmitted: true,
          reward: rewardFromNotification,
          id: survey.id
        });
        if (rewardFromNotification)
          this.setState({
            finishSurvey: true,
            loading: false,
            getReward: true,
            reward: rewardFromNotification
          });
        else
          this.setState({
            finishSurvey: true,
            loading: false,
            reward: rewardFromNotification
          });
      })
      .catch(err => {
        this.setState({ loading: false });
        const errorCode = get(err, 'error.code');
        if (errorCode === 'campaigns/expired')
          return alert('แงงง!แคมเปญนี้ หมดอายุแล้วจ้าา');
      });
  };

  componentDidMount() {
    const { surveys } = this.props;
    let url_string = window.location.href;
    let url = new URL(url_string);
    const surveyId = url.searchParams.get('surveyId');

    if (surveys.length === 0) {
      this.props.getNewNotification(surveyId).then(data => {
        this.setSurveyState(data.value);
      });
    } else if (surveys.length !== 0) {
      // console.log(surveys.find(survey => survey.id === surveyId))
      this.setSurveyState(surveys.find(survey => survey.id === surveyId));
    }
  }

  render() {
    const { classes, profile } = this.props;
    const {
      survey,
      finishSurvey,
      canDoSurvey,
      reward,
      loading,
      getReward
    } = this.state;
    // console.log('survey', survey);
    return (
      <div>
        {survey && profile ? (
          <div
            className={classes.surveysContainer}
            style={{
              backgroundColor: Color(survey.brandColor)
                .lighten(0.1)
                .hex()
            }}>
            <div
              className={classes.header}
              style={{ backgroundColor: survey.brandColor }}>
              <Link to="/MyAccount">
                <IconButton
                  className={classes.backIcon}
                  //   component={Link}
                  to={'/notification'}>
                  <Back style={{ fontSize: 40 }} />
                </IconButton>
              </Link>
              <Grid container spacing={16} justify="center" alignItems="center">
                <Grid item>
                  <div style={{ textAlign: '-webkit-center' }}>
                    <Image src={survey.brandLogo} className={classes.logo} />
                    <Text
                      fontSize={25}
                      fontWeight="bold"
                      style={{ color: 'white' }}
                      fontFamily={'thai-sans-neue'}>
                      {survey.brandName}
                    </Text>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div
              className={classes.surveysForm}
              style={{
                margin: 'auto',
                boxShadow:
                  '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
              }}>
              {!finishSurvey ? (
                <div>
                  {!canDoSurvey && (
                    <div>
                      <Image src={SadPumpkin} />
                      <Text
                        fontFamily={'thai-sans-neue'}>
                        เสียใจด้วย
                      </Text>
                      <Text
                        fontFamily={'thai-sans-neue'}>
                        คุณไม่สามารถทำแบบสำรวจนี้ได้
                      </Text>
                    </div>
                  )}
                  {canDoSurvey && (
                    <div>
                      {get(survey, 'reward', false) && (
                        <div className={classes.rewardPreview}>
                          <Image
                            src={reward.image}
                            className={classes.rewardImage}
                          />
                          <div>
                            <Text
                              fontFamily={'thai-sans-neue'}
                              fontSize={22}
                              fontWeight="bold">
                              {reward.title}
                            </Text>
                            <Text
                              fontFamily={'thai-sans-neue'}
                              fontSize={15}
                              color={'grey'}>
                              {reward.description}
                            </Text>
                          </div>
                        </div>
                      )}
                      <Text
                        fontFamily={'thai-sans-neue'}
                        fontSize={25}
                        fontWeight="bold">
                        {survey.title}
                      </Text>
                      <Text fontFamily={'thai-sans-neue'}>
                        {survey.subtitle}
                      </Text>
                      {survey.items.map((item, index) => (
                        <div className={classes.formBox} key={item.id}>
                          <Text
                            fontFamily={'thai-sans-neue'}
                            fontWeight="semiBold">
                            {index + 1}. {item.title}
                          </Text>
                          <RadioGroup
                            aria-label="Gender"
                            name="gender1"
                            className={classes.group}
                            value={this.state.answers[index]}
                            onChange={this.handleChange.bind(this, index)}>
                            {item.options.map((option, choiceIndex) => (
                              <FormControlLabel
                                key={option.id}
                                value={option.id}
                                control={
                                  <StyledRadio
                                    color={survey.brandColor}
                                    classes={{
                                      root: 'radio-root',
                                      checked: 'checked'
                                    }}
                                  />
                                }
                                label={this.renderOptionLabel(
                                  option,
                                  index,
                                  choiceIndex
                                )}
                              />
                            ))}
                          </RadioGroup>
                        </div>
                      ))}
                      <div style={{ textAlign: 'center', marginTop: 20 }}>
                        <Button.Contain
                          disabled={this.checkDisableButton()}
                          onClick={() => this.submitAnswers()}
                          loading={loading}
                          rounded
                          style={{
                            width: 150,
                            backgroundColor: this.checkDisableButton()
                              ? 'grey'
                              : survey.brandColor,
                            color: 'white'
                          }}>
                          Submit
                        </Button.Contain>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <SurveysReward
                  reward={reward}
                  expiredDate={survey.expiredDate}
                  brandName={survey.brandName}
                  getReward={getReward}
                />
              )}
            </div>
          </div>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Text
              fontFamily={'thai-sans-neue'}
              style={{ marginTop: '50%' }}
              fontWeight={'bold'}>
              กำลังโหลดข้อมูล กรุณารอสักครู่
            </Text>
            <Loader />
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  connect(
    state => ({
      surveys: selectData(state),
      getSurveysStatus: selectSurveysStatus(state)
    }),
    dispatch =>
      bindActionCreators(
        {
          getNewNotification,
          updateSurvey
        },
        dispatch
      )
  ),
  withStyles(styles),
  withProfile
)(Survey);
